var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('ButtonGeneric', _vm._g({
          staticClass: "mx-0",
          attrs: {
            "disabled": _vm.shouldDisableArchiveButton,
            "is-removing": _vm.isSaving,
            "color": "var(--v-secondary-base)"
          },
          on: {
            "click": _vm.onClickOfArchiveButton
          }
        }, on), [_c('span', {
          attrs: {
            "slot": "icon"
          },
          slot: "icon"
        }, [_vm.isArchived ? _c('i', {
          staticClass: "mr-2",
          class: _vm.$icons.unarchive
        }) : _c('i', {
          staticClass: "mr-2",
          class: _vm.$icons.archived
        })]), _vm.isArchived ? _c('span', [_vm._v("Unarchive")]) : _c('span', [_vm._v("Archive")]), _vm.isArchived ? _c('template', {
          slot: "removing"
        }, [_vm._v("Unarchiving...")]) : _c('template', {
          slot: "removing"
        }, [_vm._v("Archiving...")])], 2)];
      }
    }])
  }, [_vm.isArchived ? _c('span', [_vm._v("Unarchiving this Risk allows it to be selected.")]) : _c('span', [_vm._v("Archiving this Risk removes it's selectability.")])]), _vm.showDialog ? _c('ModalConfirmationDelete', {
    attrs: {
      "show-delete-alert": false,
      "modal-width": "50vw"
    },
    on: {
      "affirmativeClicked": _vm.onAffirmativeClicked,
      "negativeClicked": _vm.onNegativeClicked
    },
    scopedSlots: _vm._u([{
      key: "html",
      fn: function () {
        return [_c('h3', {
          staticClass: "mb-3"
        }, [_c('i', [_vm._v("Archiving")]), _vm._v(" will make the Risk unable to be selected moving forward.")]), _c('h3', {
          staticClass: "mb-3"
        }, [_c('i', [_vm._v("Unarchiving")]), _vm._v(" allows the selection of this Risk.")])];
      },
      proxy: true
    }, {
      key: "remove-button-text",
      fn: function () {
        return [_vm._v("Yes, I'm sure.")];
      },
      proxy: true
    }], null, false, 1337102827),
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };